<template>
  <Item
    v-on:current-data="setCurrentData"
    ref="item"
  >
    <v-layout align-start justify-center row>
      <v-flex xs12 sm12 md8>
        <ChatField
          v-if="chatField"
          :componentData="chatField"
          :readonly="readonly"
          v-on:change="sendMessage($event)"
        >
        </ChatField>
      </v-flex>
    </v-layout>
  </Item>
</template>

<script>
import _ from '../../../misc/dataAccess';
import Item from '../../generic/Item.vue';
import ChatField from '../../../components/crud/fields/chatField.vue';

export default {
  name: 'IssueDetails',
  components: {
    ChatField,
    Item,
  },
  props: {
    componentData: {
      type: Object,
    },
  },
  data() {
    return {
      currentData: {},
      loading: [],
      chatField: false,
    };
  },
  computed: {
    currentPage() {
      return (this.componentData && this.componentData.options)
        || this.$store.state.settings.currentPage;
    },
    options() {
      return this.currentPage.options[0];
    },
    readonly() {
      return this.options.permissions.indexOf('update') === -1;
    },
    object() {
      return this.options.object;
    },
    alias() {
      return this.options.alias || this.options.object;
    },
    plugin() {
      return this.options.plugin;
    },
    objectId() {
      return (this.componentData && this.componentData.value)
        || this.$route.params.objectId;
    },
  },
  watch: {
    currentData() {
      this.computeFields();
    },
  },
  methods: {
    async computeFields() {
      const chatValue = _.get(this.currentData, '_source.history', [])
        .filter((message) => (!!message.message));

      if (this.currentData._source.message) {
        chatValue.push(this.currentData._source);
      }

      this.chatField = {
        fid: 'issue-chat',
        label: 'pages.issue details.issueChat',
        description: '',
        path: '_source.history',
        value: chatValue,
        component: 'chatField',
        options: {
          loading: this.loading.indexOf('sendMessage') > -1,
          from: {
            path: 'author',
            format: {
              type: 'populated',
              object: 'users',
              plugin: 'environment-manager',
              path: '_source.email',
            },
          },
          when: {
            path: 'timestamp',
            format: {
              type: 'dateTime',
            },
          },
          timestamp: {
            path: 'timestamp',
          },
          content: {
            path: 'message',
          },
        },
      };
    },
    setCurrentData(data) {
      this.currentData = data;
    },
    async sendMessage(message) {
      this.toggleLoading('sendMessage');
      this.$nextTick(async () => {
        await this.$store
          .dispatch('crud/UPDATE', {
            object: this.object,
            alias: this.alias,
            objectId: this.objectId,
            plugin: this.plugin,
            body: {
              timestamp: Date.now(),
              message,
              author: this.$store.state.auth.currentUserffly4u.id,
            },
          });
        await this.$refs.item.computeFields();
        setTimeout(() => {
          // we put a timeout in order for the event to be visible
          this.toggleLoading('sendMessage');
        }, 500);
      });
    },
    toggleLoading(key) {
      const keyIndex = this.loading.indexOf(key);

      if (keyIndex > -1) {
        this.loading.splice(keyIndex, 1);
      } else {
        this.loading.push(key);
      }
    },
  },
};
</script>
