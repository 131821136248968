<template>
  <div class="v-text-field">
    <v-layout row>
      <v-flex xs12>
        <v-subheader
          light
          class="v-label v-label--active"
          :style="{'padding-left': '12px'}"
          style="margin-bottom: -30px; width:100%">
            {{ $t(label) }}
        </v-subheader>
      </v-flex>
      <v-flex v-if="$t(tooltip)" style="width: 24px; margin-right: 12px;">
        <v-layout fill-height align-center justify-end>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="ml-12" slot="activator" v-on="on">mdi-help-circle</v-icon>
            </template>
            <span>{{ $t(tooltip) }}</span>
          </v-tooltip>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row align-center justify-left
      v-for="(message, index) in messages"
      :key="index"
    >
      <v-flex
        xs12 sm12 md6
        :offset-md6="message.right"
        class="mt-4">
        <v-card class="elevation-2"
          :class="{
            'text-right': message.right,
            'text-left': !message.right,
          }"
        >
          <v-card-text v-if="message.right">{{ $t('misc.you') }} - {{ message.when }}</v-card-text>
          <v-card-text v-else>{{ message.from }} - {{ message.when }}</v-card-text>
          <v-card-text class="black--text">
            {{ message.content }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row align-center justify-center>
      <v-flex xs12 sm12 md8 class="mt-12" v-if="!fieldReadonly">
        <v-text-field
          style="border-radius: 20px"
          v-model="message"
          class="mt-7"
          :label="$t('fields.chatInstruction')"
          append-icon="mdi-send"
          v-on:click:append="sendMessage"
          :loading="loading"
          solo
        ></v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import _ from '@/misc/dataAccess';
import lodash from '@/misc/lodash';

export default {
  name: 'chatField',
  props: {
    componentData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      label: this.componentData.label || 'fields.chat',
      color: this.componentData.color || 'accent',
      message: '',
      messages: [],
      tooltip: (typeof this.componentData.description === 'undefined')
        ? 'fields.chatTooltip' : this.componentData.description,
      fieldReadonly: this.readonly || !!this.componentData.readonly,
      loading: this.componentData.options.loading || false,
    };
  },
  watch: {
    componentData() {
      if (!lodash.isEqual(this.fieldReadonly, this.componentData.readonly)
        || !lodash.isEqual(this.fieldReadonly, this.readonly)) {
        this.fieldReadonly = this.readonly || !!this.componentData.readonly;
      }

      this.loading = this.componentData.options.loading || false;
      this.computeMessages();
    },
  },
  methods: {
    async computeMessages() {
      let messages = [];
      const rawMessages = this.componentData.value;

      for (let index = 0; index < rawMessages.length; index += 1) {
        const rawMessage = rawMessages[index];

        const right = _.get(rawMessage, this.componentData.options.from.path)
          === this.$store.state.auth.currentUserffly4u.id;
        let from;
        if (!right) {
          // eslint-disable-next-line no-await-in-loop
          from = await _.format(
            _.get(rawMessage, this.componentData.options.from.path),
            this.componentData.options.from.format,
          );
        }

        // eslint-disable-next-line no-await-in-loop
        const when = await _.format(
          _.get(rawMessage, this.componentData.options.when.path),
          this.componentData.options.when.format,
        );

        // eslint-disable-next-line no-await-in-loop
        const timestamp = await _.format(
          _.get(rawMessage, this.componentData.options.timestamp.path),
          this.componentData.options.timestamp.format,
        );

        // eslint-disable-next-line no-await-in-loop
        const content = await _.format(
          _.get(rawMessage, this.componentData.options.content.path),
          this.componentData.options.content.format,
        );

        messages.push({
          right,
          from,
          when,
          timestamp,
          content,
        });
      }

      messages = messages.filter((message, index, array) => {
        const messageIndex = array.findIndex((mI) => (mI.timestamp === message.timestamp
          && mI.from === message.from
          && mI.content === message.content
        ));

        return messageIndex === index;
      });

      messages.sort((mA, mB) => (mA.timestamp - mB.timestamp));

      this.messages = messages;
    },
    sendMessage() {
      this.$emit('change', this.message);
      this.message = '';
    },
  },
  async mounted() {
    await this.computeMessages();
  },
};
</script>
